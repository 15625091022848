@import url('https://fonts.googleapis.com/css?family=Roboto:400');

:root {
    /* --primary-clr: #6200EE; */
    --primary-clr: #007AFF;
    --purple-clr: #5856D6;
    --blue-clr: #007AFF;
    --bg-gray-clr: #FAFAFA;
    --gray-txt-clr: #979696;
    --gray-clr: #8F9294;
    --black-txt-clr: #0E202A;
    --green-clr: #34C759;
    --orange-clr: #FFEBC5;
    --error-clr: #FF0000;
    --error-clr2: #B00020;
    --black-clr: #000000;
    --gray-bg-clr: #F8F8F8;
    --green-bg-clr: #DFEFEF;
    --skyblue-bg-clr: #C9E8FF;
    ;

    /* gray color list */
    --gray1: #333333;
    --gray2: #4F4F4F;
    --gray3: #828282;
    --gray4: #BDBDBD;
    --gray5: #E0E0E0;
    --gray6: #F2F2F2;
    --gray7: #D5D5D5;
    /* gray color list */

}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 16px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px;
}

.fw-600 {
    font-weight: 600;
}

.primary-clr {
    color: var(--primary-clr) !important;
}

.blue-bg-clr {
    background-color: var(--blue-clr) !important;
}

.error-clr {
    color: var(--error-clr) !important;
}

.error-clr2 {
    color: var(--error-clr2) !important;
}

.green-clr {
    color: var(--green-clr) !important;
}

.green-bg-clr {
    background-color: var(--green-bg-clr) !important;
}

.input-gray-clr {
    color: var(--gray4) !important;
}

.bg-gray-clr {
    background-color: var(--bg-gray-clr) !important;
}

.skyblue-bg-clr {
    background-color: var(--skyblue-bg-clr) !important;
}

.bg-gray5-clr {
    background-color: var(--gray5) !important;
}

.border-gray-clr {
    border: 1px solid var(--gray5) !important;
}

.gray-txt-clr {
    color: var(--gray-txt-clr) !important;
}

.gray-clr {
    color: var(--gray-clr) !important;
}

.gray2 {
    color: var(--gray2) !important;
}

.gray3 {
    color: var(--gray3) !important;
}

.gray4 {
    color: var(--gray4) !important;
}

.gray-bg-clr {
    background-color: var(--gray-bg-clr) !important;
}

.border_right {
    border-right: 1px solid var(--gray5);
}

.border_left {
    border-left: 1px solid var(--gray5);
}

.black-clr {
    color: var(--black-clr) !important;
}

.border-radius_5 {
    border-radius: 5px !important;
}


.border-radius_tr {
    border-radius: 5px 5px 0px 0px;
}

.border-radius_br {
    border-radius: 0 0 5px 5px;
}

.br_5 {
    border-radius: 5px !important;
}

.br_20 {
    border-radius: 20px !important;
}

.rotate_90 {
    transform: rotate(-90deg);
}

.line-ht-17 {
    line-height: 17px !important;
}

.position-unset {
    position: unset !important;
}

.mt_70 {
    margin-top: 70px !important;
}

.mt_80 {
    margin-top: 80px !important;
}

.w_75 {
    width: 75%;
}

html {
    font-size: 100%;
}

.btn:focus,
input:focus,
textarea:focus {
    outline: 0;
    box-shadow: none !important;
}

body {
    background: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    color: #000000;
}

.w-45 {
    width: 45%;
}

.translateY_90 {
    transform: rotate(90deg);
}

.translateY_180 {
    transform: rotateY(180deg);
}

.pointer-none {
    pointer-events: none;
}

.main-section {
    height: 100vh;
    margin-top: 60px;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    text-decoration: none;
    color: #000000;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--primary-clr);
    background-color: #e9ecef;
}



.row {
    margin: 0;
}

.signup-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 612px;
}

.login-img-section {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-img-section img {
    max-width: 612px;
}

.start-txt {
    font-weight: 500;
    font-size: 20px;
}

.create-txt {
    color: var(--black-txt-clr);
    font-weight: 600;
    font-size: 34px;
}

.button_actions {
    width: 200px;
    height: 40px;
    border: 0.5px solid var(--primary-clr);
    border-radius: 40px;
    background: #FFFFFF;
    color: var(--black-txt-clr);
    /* font-weight: 600; */
    display: block;
    transition: all 0.3s ease-in-out;
}

.social_btns {
    width: 243px;
    height: 40px;
    border: 1px solid var(--gray6);
    border-radius: 40px;
}

.blue_btn {
    height: 40px;
    min-width: 114px;
    color: #fff;
    font-size: 16px;
}

.button_actions:hover {
    background-color: var(--primary-clr);
    color: #fff;
}

.input-grp {
    margin-bottom: 10px;
}

.input-grp label {
    /* margin-bottom: 4px; */
    display: block;
    color: #000000;
}

.welcome-text {
    font-size: 28px;
}

.login-input-grp input {
    width: 100%;
    height: 36px;
    border: 0;
    color: var(--gray3);
    border-bottom: 1px solid var(--gray4) !important;
    border-radius: 0 !important;
    padding-left: 0;
}

.login-input-grp input:focus {
    border-bottom: 1px solid var(--primary-clr) !important;
}

.login-input-grp .eye {
    top: 8px !important;
}

.input-grp input {
    width: 100%;
    height: 36px;
    border: 0.5px solid var(--gray4) !important;
    border-radius: 6px !important;
}

.profile_input input {
    height: 50px;
}

.profile_input label {
    font-weight: 600;
}

.profile_price_plan {
    background: rgba(0, 122, 255, 0.03);
    padding: 8px 20px;
    border-radius: 5px;
    border: 1px solid var(--primary-clr);
}

.profile_price_plan .price {
    font-size: 40px;
    font-weight: 600;
}

.profile_price_plan .per_month_txt {
    margin-left: -8px;
}

.profile_price_plan .current_plan_tag {
    font-size: 14px;
    width: 117px;
    height: 30px;
    margin-right: -21px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: var(--primary-clr);
    color: #fff;
    position: relative;
    font-style: italic;
}

.profile_price_plan .current_plan_tag::before {
    position: absolute;
    left: 0;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid #fff;
    border-bottom: 15px solid transparent;
}

.profile_price_plan .plus::before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-clr);
    margin-right: 10px;
}

.eye {
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 20px;
}

button:focus-visible {
    outline: none;
}

hr {
    color: var(--gray4);
    margin: 0;
}

.main_content {
    /* height: calc(100vh - 122px); */
    height: calc(100vh - 108px);
    margin-top: 55px !important;
    overflow: auto;
    padding: 12px 92px;
}

/* input:focus {
    box-shadow: none !important;
    outline: none !important;
} */

.create-btn {
    color: #fff;
    background-color: var(--primary-clr);
}

.profile-save-btn {
    width: 141px;
}

.create_ac_btn {
    border: 0.5px solid var(--primary-clr);
    border-radius: 5px;
    background: #FFFFFF;
    color: var(--primary-clr);
    font-weight: 600;
    font-weight: 600;
    padding: 6px 26px;
}


/* above is previous styles */








/* add this to above.... it is already wrote in line no 90 just added the color property*/

.button_actions:hover {
    background-color: var(--primary-clr);
    color: #fff;
}

/* add this to above.... it is already wrote in line no 90 just added the color property*/





/* crate-event page styles start*/

.create-event-section {
    /* width: 50%;
    margin: 0 auto; */
    position: relative;
    padding-top: 82px;
}

.form-section {
    width: 50%;
}

.pointer {
    cursor: pointer;
}

.black-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 13px;
    background: #6A6A6A !important;
}

.event-permission {
    font-size: 20px;
}

.event-permission span {
    font-size: 12px;
}

.form-check-input:checked {
    background-color: var(--primary-clr);
    border-color: var(--primary-clr);
}

.form-check-input:focus {
    box-shadow: 0 0 0 .25rem rgba(98, 0, 238, .25);
}

.alert-message {
    color: var(--green-clr);
    background-color: #D4EEEE;
    line-height: 32px;
    font-size: 14px;
}

.orange-bg-message {
    color: #000000;
    background-color: var(--orange-clr);
}

.flatpickr-input[readonly] {
    padding: 4px 8px;
    border: 1px solid var(--gray5);
    border-radius: 5px;
    outline: 0;
}

.calender-icon {
    position: absolute;
    top: 6px;
    right: 5px;
}


/* crate-event page styles Ended*/


/* memo-event page styles starts*/
.memo-event-section {
    /* background-color: #F8F8F8; */
    position: relative;
    /* padding: 0 80px; */
    margin-top: 0 !important;
    height: unset;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.memo-event-section .infinite-scroll-component__outerdiv {
    height: 262px !important;
    overflow-y: auto !important;
}

.upload-img-section {
    /* height: calc(100vh - 258px); */
    height: calc(100vh - 184px);
    /* height: calc(100vh - 305px); */
    position: relative;
    overflow-y: auto;
}

.event_upload_section {
    /* height: calc(100vh - 54px) !important; */
    position: relative;
    overflow-y: auto;
}

.selected_height {
    height: calc(100vh - 102px) !important;
}

/* .unselected_height {
    height: calc(100vh - 102px) !important;
} */


.all_event_section {
    height: calc(100vh - 200px);
}

.media_settings_section {
    height: calc(100vh - 305px);
    position: unset;
}

.middle-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.modal-body {
    padding: 1em 3em;
}

.w-unset {
    width: unset;
}

.drag-section {
    background: #FAFCFE;
    border: 1px dashed var(--gray4);
    border-radius: 5px;
    padding: 40px;
    position: relative;
}

.img-collection {
    background: linear-gradient(90deg, rgba(98, 0, 238, 0.04) 0%, rgba(250, 252, 254, 0) 100%);
    border: 1px solid var(--gray5);
    border-radius: 5px;
    padding: 8px;
}

.event-name-section {
    display: flex;
    align-items: center;
    height: 86px;
    padding: 20px 92px;
}

/* memo-event page styles Ended*/

/* Gallery section styles Start*/

.gallery-section {
    padding: 0 92px;
}

.gallery-section ul li {
    padding: 3px;
}

.users-section ul li {
    width: 100%;
}

.users-section {
    max-height: calc(100vh - 328px);
}

.check_imgs ul {
    list-style-type: none;
}

.check_imgs li {
    display: inline-block;
}

.check_imgs input[type="checkbox"][id^="myCheckbox"] {
    display: none;
}

.form-check-input[type=checkbox] {
    border-radius: 50%;
    border: 1px solid var(--gray3);
}

.check_imgs label {
    /* border: 1px solid #fff; */
    /* padding: 10px; */
    display: block;
    position: relative;
    /* margin: 10px; */
    cursor: pointer;
}

/* .img_hover_text {
    display: none;
}
/* .check_imgs label:hover .img_hover_text {
    display: block;
} */
.memo_text_top {
    position: absolute;
    top: 20px;
    left: 20px;
}

.checkbox_section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    color: #fff;
    opacity: 0;
    height: 60px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 100%);
    transition: background 150ms ease-in-out, opacity 150ms ease-in-out;
    z-index: 1;
}

.like_share_section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    color: #fff;
    opacity: 0;
    height: 60px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.46) 100%);
    transition: background 150ms ease-in-out, opacity 150ms ease-in-out;
    z-index: 1;
}

.img-holder {
    position: relative;
    /* filter: grayscale(100%); */
    /* transform: scale(1.02); */
    transition: all 0.2s ease-in-out;
}

.checkbox_button {
    position: absolute;
    top: 10px;
    left: 12px;
    color: #fff;
    padding: 2px;
    opacity: 0;
    font-weight: 100;
}

.event_profile_img {
    position: absolute;
    top: 10px;
    right: 12px;
    opacity: 0;
}

.memo_text_bottom {
    /* background: #FBF8FF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
    padding: 6px 20px;
    cursor: pointer;
}

.settings_max_height {
    max-height: 430px;
    overflow-y: auto;
    padding-bottom: 15px;
    padding-right: 15px;
}

.settings_media_bottom {
    padding: 12px 20px;
}

.settings_users_bottom img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--gray4);
}

.settings_users_bottom {
    padding: 21px 20px;
    background: linear-gradient(90deg, rgba(98, 0, 238, 0.1) 0%, rgba(250, 252, 254, 0) 100%);
}

.check_imgs label:before {
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 17px;
    height: 17px;
    text-align: center;
    line-height: 15px;
    z-index: 99;
    display: none;
}

.check_imgs label img {
    height: 370px;
    width: 370px;
}

.memo-img {
    height: 370px;
    width: 370px;
}


.memo_img_hover label:hover:before {
    display: block;
}

.memo_img_hover label:hover .like_share_section {
    opacity: 1;
}

.img-holder:hover {
    /* filter: grayscale(0); */
    /* transform: scale(1); */
    transform-origin: 50% 50%;
}

.img-holder:hover .checkbox_button {
    opacity: 0.8;
}

.img-holder:hover .event_profile_img {
    opacity: 1;
}

.img-holder:hover .checkbox_section {
    opacity: 1;
}

.img-holder:hover .like_share_section {
    opacity: 1;
}

/* .memo_img_hover input:checked label::before,
.like_share_section {
    display: block;
    opacity: 1;
} */

.check_imgs :checked+label::before {
    display: block;
}

.check_imgs :checked+label:before {
    content: "✓";
    border-color: transparent;
    background-color: var(--primary-clr);
    /* transform: scale(1); */
}

.check_imgs :checked+label img {
    /* transform: scale(0.9); */
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}


.filter-btn {
    border: 1px solid var(--primary-clr);
    padding: 6px 24px !important;
    border-radius: 24px;
}

.filter-btn.dropdown-toggle::after {
    display: none;
}

.gap1 {
    gap: 1rem;
}

.gap2 {
    gap: 2rem;
}

.gap3 {
    gap: 3rem;
}

.gap4 {
    gap: 4rem;
}

.upload-fixed-section {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
}

.upload-fixed-section .inner_div ul li a {
    font-size: 14px;
}

.upload-fixed-section .inner_div {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
}

.upload-fixed-section .inner_div .upload-btn {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 20px;
    font-weight: 600;
}

/* All events styles End */


/* Events media styles End */
/* 
.event-media-section {
    padding: 12px 92px;
}
.event-media-img {
    margin: 16px 0 30px 0;
    height: 520px;
    border-radius: 5px;
}
.post-btns {
    position: absolute;
    bottom: 20px;
    right: 15px;
    font-size: 14px;
}
.submit-btns {
    bottom: 7px;
    right: 5px;
    color: var(--primary-clr);
    font-weight: 600;
    font-size: 14px;
}
textarea,
.reply-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray4);
    padding: 11px;
}
.reply-input-section {
    margin-top: 12px;
    width: 95%;
    margin-left: auto;
}
.favorate-section {
    display: flex;
    align-items: center;
}
.favorate-section span {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 4px;
    font-size: 18px;
    color: var(--gray4);
}
.comments-dropdown {
    width: 100%;
    box-shadow: none !important;
    border: 0 !important;
    border-top: 1px solid var(--gray4) !important;
    position: unset !important;
    transform: unset !important;
    border-radius: 0 !important;
} */


.upload-btn_event {
    color: #fff;
    padding: 12px 35px;
    border-radius: 5px;
}

.upload-btn_event {
    color: #fff;
    padding: 12px 35px;
    border-radius: 5px;
}

.upload-btn_event:hover {
    color: #fff;
    background-color: var(--black-clr);
}

.gallery_nav_icons .material-icons:hover {
    color: var(--primary-clr) !important;
}

.event-img_dropdown {
    transform: translate(0px, 45px) !important;
    left: 0 !important;
    right: 0 !important;
    /* border-radius: 0 0 5px 5px !important; */
    background: #FBF8FF;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%) !important;
    padding: 6px 20px !important;
}

.subDropdown.dropdown-menu {
    position: absolute !important;
    right: 167px !important;
    top: 0 !important;
}

/* Events media styles End */

/* Search styles Start */

.search-section {
    width: 50%;
    margin: 0 auto;
    padding: 40px 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

/* Search styles End */



/* Navbar section styles started*/
.logo {
    width: 50px;
}

.nav-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 5px 80px;
    justify-content: space-between;
    /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
    border-bottom: 1px solid var(--gray5);
}

.nav-section .nav-link {
    margin-left: 15px;
}

.nav-section .nav-link {
    color: #000000 !important;
}

.nav-section .nav-link.dropdown-toggle {
    display: flex !important;
    align-items: center;
}

.nav-section .nav-link.dropdown-toggle::after {
    margin-left: 0.7em;
}

.nav-section .nav-link:last-child {
    padding-right: 0 !important;
}

.nav-section .nav-link:hover {
    color: var(--primary-clr) !important;
}

.dropdown-menu {
    padding: 0;
    border: 0;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 16%);
    border-radius: 5px;
}

.dropdown-menu[data-bs-popper] {
    left: 24px;
}

.dropdown-menu .active {
    background: #FBF8FF;
    color: var(--primary-clr);
}

.settings_dropdown_menu {
    width: 80px;
}

.settings_dropdown_menu li {
    padding: 0 !important;
}

.dropdown-toggle::after {
    margin-left: 1em;
    transform: rotate(0deg);
}

.dropdown-toggle.show::after {
    transform: rotate(180deg);
}


.modal_content {
    width: 50%;
}

.profile-pic-navbar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 6px;
}

.profile-pic {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 6px;
}

/********* Navbar section styles Ended ************/


/* Footer section styles started*/
.footer-section {
    padding: 12px 92px;
}

.footer-ul {
    /* border-left: 1px solid var(--gray4); */
    flex-flow: wrap;
    /* justify-content: center; */
    gap: 1rem;
}

.footer-ul li {
    padding: 0 6px;
    padding-right: 16px;
    color: var(--gray4);
}

.footer-ul li a {
    text-decoration: unset;
    color: unset;
}

.footer-ul li a:hover {
    color: var(--primary-clr) !important;
}

/* Footer section styles Ended*/



/* Events media styles End */

.form_group_events {
    margin: 0 !important;
}

.event-media-section {
    padding: 12px 92px;
}

.forward_back_btns {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    justify-content: space-between;
    padding: 0 12px;
}

.forward_back_btns_mobile {
    position: inherit;
    transform: unset;
}

.forward_back_btns button {
    background-color: var(--gray7);
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 0;
    outline: 0;
}

.forward_back_btns button.disable {
    opacity: 0.5;
}

.comments_name-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EED8FF;
    font-size: 14px;
}

.comments_profile_circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EED8FF;
    font-size: 14px;
}

.edit_profile_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--primary-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit_profile_img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.camera_circle {
    position: absolute;
    right: -4px;
    bottom: 10px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: var(--purple-clr);
}

.change_password {
    white-space: nowrap;
    font-weight: 600;
    color: var(--primary-clr);
    text-decoration: underline;
}

.change_password_btn {
    height: 50px;
    width: 168px;
}

.profile_pic_comments {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.action_btn_img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.comments_title {
    padding: 4px 48px !important;
}

.comment_main_section {
    height: calc(100vh - 46px);
}

.event-media-img {
    /* height: 480px; */
    height: 460px;
    max-width: 100%;
}

.event-media-comments {
    /* max-height: 480px; */
    /* max-height: 420px;
    overflow-y: auto; */
    border-radius: 5px;
    /* margin-right: -15px; */
    padding-right: 10px;
}

.box_shadow {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}

.post-btns {
    font-size: 14px;
}

.post-btns button {
    width: unset;
}

.submit-btns {
    bottom: 7px;
    right: 5px;
    color: var(--primary-clr);
    font-weight: 600;
    font-size: 14px;
}

textarea {
    background-color: #F6F6F6;
    height: 140px;
}

textarea::placeholder {
    color: #000000;
    opacity: .25;
}

textarea,
.reply-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray4);
    padding: 4px 10px;
}

.reply-input-section {
    /* margin-top: 12px; */
    /* width: 95%; */
    margin-left: auto;
}

.favorate-section {
    display: flex;
    align-items: center;
}

.fav_icon_circle {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #D4D4D4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.favorate-section span {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 4px;
    font-size: 20px;
    color: var(--gray3);
}

.all-comments-section {
    line-height: 1.4;
}

.comments-dropdown {
    width: 100%;
    box-shadow: none !important;
    border: 0 !important;
    border-top: 1px solid var(--gray4) !important;
    position: unset !important;
    transform: unset !important;
    border-radius: 0 !important;
}

.loading_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
}

/* settings styles End */

.event_main_content {
    height: 100vh;
    overflow: auto;
    padding: 12px 92px;
    /* z-index: 9999;
    position: relative; */
}

.tabs-main-section {
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
}

.tabs-section {
    color: var(--gray3);
    position: relative;
    list-style-type: none;
    height: max-content;
}

.tabs-section li {
    padding: 6px 0;
    cursor: pointer;
    position: relative;
}

.tabs-section::before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    background-color: var(--gray5);
}

.tabs-section .active {
    color: var(--primary-clr);
    font-weight: 600;
}

.tabs-section .active.tab_li::before {
    content: '';
    display: block;
    width: 4px;
    height: 38px;
    border-radius: 5px;
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    background-color: var(--primary-clr);
}

.note_txt {
    color: #6A6A6A;
}


/* settings styles End */


/* Events media styles End */


/* Pricing styles Started */
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--primary-clr);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-clr);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}



.price-box {
    width: 25%;
    height: 500px;
    padding: 20px 30px;
    border-radius: 0;
    color: var(--gray3);
    position: relative;
    max-width: 315px;
}

.price-box:hover {
    color: #fff;
    background-color: var(--primary-clr);
}

.price-box:hover .plan-btn {
    background-color: #fff;
    color: var(--primary-clr);
}

.price-box:hover .material-icons {
    color: #fff;
}

.price-box .tag {
    font-weight: 600;
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 10px !important;
    background-color: #F3EBFF;
    color: var(--primary-clr);
    min-width: 110px;
    padding: 4px;
    text-transform: uppercase;
}

.plan-btn {
    border: 0;
    color: white;
    background-color: #000000;
    padding: 10px;
    width: 80%;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
}



/* Pricing styles End */


/* Profile styles End */

.profile-main-section {
    max-width: 500px;
    margin: 0 auto;
}

.heading_text {
    font-weight: 700;
    font-size: 28px;
}

/* Profile styles End */


.infinite-scroll {
    overflow: unset !important;
}


/* Srollbar width and color */
*::-webkit-scrollbar {
    width: .4em;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
}

/* Srollbar width and color */








/********* media queries *********/

@media screen and (max-width:768px) {
    .logo {
        width: 40px;
    }

    .nav-section {
        padding: 12px 8px;
    }

    .nav-section .nav-link {
        margin-left: 0;
    }

    .nav-section .dropdown-toggle::after {
        position: absolute;
        right: 0;
        top: 20px;
    }

    .form-section {
        width: unset;
    }

    .navbar-dark .navbar-toggler {
        color: #1C1B1F;
        border: unset;
        padding: 0;
    }

    button:focus:not(:focus-visible) {
        box-shadow: none;
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url('../../../public/images/menu.svg') !important;
        width: 1.2em;
        height: 1.2em;
    }

    .nav-section .nav-link {
        padding-left: 0 !important;
    }

    .navbar-nav {
        padding-top: 24px;
    }

    .mobile-profile-section .dropdown-toggle::after {
        display: none;
    }


    .dropdown-menu[data-bs-popper] {
        left: unset;
        right: 20px;
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid var(--gray4);
    }

    .mobile-profile-section .mobile_dropdown_menu {
        border: 0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
    }

    .plan_message {
        width: 75%;
        line-height: 20px;
    }

    #collapsibleNavbar {
        padding: 15px;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }

    .collapse.show {
        transform: unset !important;
    }

    .footer-section {
        padding: 16px;
        padding-bottom: 80px;
    }

    /* .footer-section .border_right {
        border-right: none;
    } */

    .footer-ul {
        border: none !important;
    }

    .middle-section {
        width: 100%;
    }

    .upload-img-section {
        margin: 0 16px;
    }

    .memo-event-section {
        padding-left: 0;
    }

    .event-name-section {
        padding: 8px 16px;
        font-size: 12px;
        /* height: 40px; */
    }

    .gallery-section {
        padding: 0;
    }

    .gallery-bottom {
        padding-bottom: 80px !important;
    }

    .gallery-section ul li {
        padding: 0;
    }

    .check_imgs label {
        margin: 0;
        margin-bottom: 10px;
    }

    .memo_img_hover label:hover:before {
        display: none;
    }

    .memo_img_hover label:hover .like_share_section {
        opacity: 0;
    }

    .checkbox_section {
        display: none;
    }

    .like_share_section {
        position: unset;
        padding: 8px 0;
        opacity: 1 !important;
        background: unset;
    }

    .like_share_section .gray-icon {
        color: var(--gray-clr) !important;
    }

    .like_share_section_mobile {
        left: 3px;
        bottom: -10px;
    }


    /* .check_imgs :checked+label::before {
        display: none;
    } */

    .memo-event-section .infinite-scroll-component__outerdiv {
        height: unset !important;
    }

    .favorate-section span {
        border: unset;
        border-radius: unset;
    }

    .bottom_checkbox label {
        width: 15px;
        height: 15px;
        border-radius: 3px;
        line-height: 15px;
        border: 1px solid var(--primary-clr);
        position: relative;
    }

    .bottom_checkbox label:before {
        text-align: center;
        line-height: 16px;
        z-index: 99;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
    }

    .bottom_checkbox :checked+label:before {
        color: #fff;
        content: "✓";
        border-color: transparent;
        background-color: var(--primary-clr);
    }

    .check_imgs label img {
        height: 145px;
        width: 145px;
    }

    .memo-img {
        height: 290px;
        width: 290px;
    }

    .event-media-section {
        padding: 16px !important;
        font-size: 14px;
    }

    .all-comments-section {
        font-size: 14px;
    }

    .event-media-img {
        /* height: auto; */
        min-height: 50%;
        max-width: 100%;
        object-fit: cover;
    }

    .event-media-comments {
        overflow-y: unset;
        height: unset;
        margin-right: 0;
        padding-right: 0;
    }

    .box_shadow {
        box-shadow: none;
    }

    .forward_back_btns button {
        width: 26px;
        height: 26px;
    }

    .welcome-text {
        font-size: 20px;
    }

    .w_75 {
        width: 100%;
    }

    .w_50 {
        width: 50%;
    }

    .modal_content {
        width: 100%;
    }

    .main_content {
        padding: 20px;
        margin-top: 50px;
    }

    .tabs-main-section {
        width: 100%;
        padding-top: unset;
    }

    .tabs-section {
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
    }

    .tabs-section::before {
        width: 100%;
        height: 4px;
        left: 0;
        top: unset;
        bottom: -5px;
    }

    .tabs-section .active.tab_li::before {
        width: 80px;
        height: 4px;
        left: -18px;
        top: unset;
        bottom: -5px;
    }

    .gap4 {
        gap: 0;
    }

    .memo-img {
        height: 306px;
        width: 306px;
    }

    .memo-img2 {
        height: 145px;
        width: 145px;
    }

    .memo-img3 {
        height: 98px;
        width: 96px;
    }

    .upload-img-section {
        /* margin: 0; */
        padding: 0 16px;
        padding-bottom: 65px;
    }

    .memo-event-section {
        margin-top: 0 !important;
        height: unset !important;
    }

    .search-section {
        width: 100%;
        padding: 30px 20px;
    }

    .comments_title {
        padding: 0 !important;
    }

    .infinite-scroll {
        display: flex;
        flex-wrap: wrap;
        padding: 2px;
    }

    .infinite-scroll li {
        padding: 1px !important;
    }

    .cards-section {
        flex-direction: column;
    }

    .price-box {
        width: 100%;
    }

    .fs_22 {
        font-size: 22px !important;
    }

}

.alert-fixed {
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    border-radius: 0px;
    padding: 0.5rem 1rem;
    text-align: center;
}

/********* media queries *********/