/* @import url("https://fonts.googleapis.com/css?family=Alex Brush"); */
@font-face {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Alex%20Brush/Alex%20Brush.woff2) format("woff2");
}
.btn-primary {
  filter: drop-shadow(0px 8px 24px rgba(22, 27, 45, 0.1));
  background-color: #335eea;
  border-radius: 6px;
}
.btn-danger {
  background-color: #d92d20 !important;
  border-radius: 5px;
}
.btn-cancel {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  color: black !important;
}
input[type="text"]:disabled {
  background-color: white;
  color: #828282;
}
.wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
@media (max-width: 991px) {
  .notifications-open {
    height: calc(100vh - 250px) !important;
    overflow: hidden;
  }
}

/* navbar */
.logout-icon {
  stroke: #506690;
}
.nav-item:hover .logout-icon {
  stroke: #335eea;
}
.nav-link:hover {
  color: #335eea !important;
}
@media (max-width: 991px) {
  #navbarCollapse {
    margin-top: 64px;
  }
}
@media (max-width: 991px) {
  .nav-item {
    padding: 0 !important;
    padding-bottom: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 1rem !important;
  }
  .nav-link {
    margin: 0 !important;
    margin-bottom: 0.2rem !important;
  }
}
.nav-right img {
  width: 42px;
  height: 42px;
  color: #869ab8 !important;
}
.nav-link-dropdown {
  margin-left: 80px !important;
}
.nav-link {
  background: none !important;
  font-weight: 600 !important;

  line-height: 26px !important;
  color: #506690 !important;
}
.events-dropdown {
  font-weight: 400 !important;
  line-height: 152.34% !important;
  color: #000000 !important;
}
@media (max-width: 991px) {
  .nav-right-logout {
    left: 0;
  }
  .nav-right {
    margin-left: 0%;
  }
  .nav-link-dropdown {
    margin-left: 0px !important;
  }
}
nav .dropdown-item:hover {
  background: none;
  cursor: pointer;
}
nav a {
  color: #506690 !important;
}
.signup-btn {
  color: white !important;
}
/* contact-alt */
.contact-alt-chck-label {
  color: #828282 !important ;
}
.signin-input-label {
  color: #828282;
}
.contact-alt-input::placeholder {
  font-weight: 600 !important;
  color: #bdbdbd !important;
  opacity: 1;
}

.contact-alt-input:-ms-input-placeholder {
  font-weight: 600 !important;
  color: #bdbdbd !important;
  opacity: 1;
}

.contact-alt-input::-ms-input-placeholder {
  font-weight: 600 !important;
  color: #bdbdbd !important;
  opacity: 1;
}

/* date-picker */

.ui-datepicker {
  width: 300px;
  background: #ffffff;
  border-radius: 10px 10px 10px 0px;
  top: 0;
  border: none !important;
  margin: 5px auto 0;
  font: 12pt Arial, sans-serif;
  border-radius: 10px 10px 10px 0px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.ui-datepicker table {
  width: 100%;
}
.ui-datepicker {
  position: relative;
  right: 150px;
}
.ui-datepicker-header {
  background-color: white;
  border: none;
  font-family: "Times New Roman";
}

.ui-datepicker-header .ui-datepicker-month {
  position: absolute;
  left: 0;
}

.ui-datepicker-header .ui-datepicker-year {
  position: absolute;
  left: 20%;
  font-size: 150%;
}

.ui-datepicker-header .ui-datepicker-prev {
  position: absolute;
  left: 80%;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 15px;
}

.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  background-position: center -30px;
}

.ui-datepicker-next {
  float: right;
  cursor: pointer;
  background-position: center 0px;
}

.ui-datepicker thead {
  background-color: #f7f7f7;
}

.ui-datepicker th {
  background-color: #808080;
  text-transform: uppercase;
  font-size: 8pt;
  display: none;
}

.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker tbody td:last-child {
  border-right: 0px;
}

.ui-datepicker tbody tr:last-child {
  border-bottom: 0px;
}

.ui-datepicker a {
  text-decoration: none;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
}

.ui-datepicker-calendar .ui-state-default {
  background-color: white;
  color: #000;
  font-weight: 550;
  border: none;
  height: 40px;
  width: 40px;
}

.ui-datepicker-calendar .ui-state-active {
  background-color: #000;
  color: white;
}

.ui-datepicker-unselectable .ui-state-default {
  background: #d6e4be;
  color: #000;
}

/* landing page */
.landing-video-section video {
  /* border-radius: 12px; */
  border: 0 !important;
}
@media (max-width: 767px) {
  .landing-video-section {
    margin: auto;
    max-width: 550px;
  }
  .landing-preview-wrapper {
    max-width: 600px;
    width: 100%;
  }
}

.sigin-close {
  top: 1rem;
  position: relative;
  left: 99%;
}
@media (max-width: 575px) {
  .sigin-close {
    top: 0.5rem;
    left: 95%;
  }
}

.how-to-create-img-3 {
  width: 90%;
}
.home-sec-1-right {
  position: relative;
  top: 2rem;
}
.features-welcome-img-1-wrapper {
  width: 100%;
  top: 29%;
}
.landing-welcome-img-2 {
  width: 100%;
  height: 30rem;
}
.features-welcome-img-1 {
  position: relative;
  width: 31% !important;
  left: 77%;
}
.features-welcome-img-1 .device-screen {
  left: -1%;
  /* height: 100%;
  width: 68% !important;
  left: 16% !important;
  top: 7.5% !important; */
}
.features-welcome-img-2 {
  position: relative;
  width: 130% !important;
  left: -5%;
}
@media (max-width: 1300px) {
  .features-welcome-img-1-wrapper {
    top: 22%;
  }
  .features-welcome-img-2 {
    width: 114% !important;
  }
  .features-welcome-img-1 {
    width: 27% !important;
    left: 67%;
  }
}
@media (max-width: 991px) {
  .home-sec-1-right {
    top: 7rem;
  }
  .features-welcome-img-2 {
    width: 130% !important;
    left: -10%;
  }
  .features-welcome-img-1 {
    width: 30% !important;
    left: 67%;
  }
  .features-welcome-img-1-wrapper {
    top: 13%;
  }
}
@media (max-width: 767px) {
  .home-sec-1-right {
    top: 3.5rem;
  }
  .landing-welcome-img-2 {
    height: 100vw;
    max-height: 25rem;
  }
  .features-welcome-img-1-wrapper {
    top: 18%;
  }
  .features-welcome-img-2 {
    width: 100% !important;
    left: 0%;
  }
  .features-welcome-img-1 {
    width: 26% !important;
    left: 62%;
  }
}
@media (max-width: 575px) {
  .landing-welcome-img-2 {
    height: 70vw;
  }
  .features-welcome-img-1-wrapper {
    top: 20%;
  }
}
@media (max-width: 500px) {
  .features-welcome-img-1-wrapper {
    top: 14vw;
  }
}
.notable-img-2-wrapper {
  width: 100%;
  top: 40%;
  left: 45%;
}
@media (max-width: 1199px) {
  .notable-img-2-wrapper {
    left: 50%;
    top: 40%;
  }
}
.notable-img-2 {
  width: 45%;
}
.notable-img-1 .img-fluid {
  height: 100%;
  width: 85%;
}
.notable-img-1 .device-screen {
  height: 95%;
  width: 89%;
  z-index: 2;
  top: 2%;
  left: 1.8%;
}
@media (max-width: 991px) {
  .notable-img-2-wrapper {
    left: 54%;
    top: 40%;
  }
}
@media (max-width: 767px) {
  .sec-6-left-wrapper {
    position: relative;
  }
  .notable-img-1 .device-screen {
    width: 89%;
  }

  .notable-img-2-wrapper {
    top: 41%;
    width: 103%;
    left: 58%;
  }
}
@media (max-width: 575px) {
  .notable-img-1 {
    width: 90%;
  }
  .notable-img-2-wrapper {
    left: 45%;
    width: 86%;
    top: 47%;
  }
  .sec-6-left-wrapper {
    left: 10%;
  }
}
@media (min-width: 992px) {
  .sec-6-bot {
    position: relative;
    left: -4%;
  }
}

.features-img-1 .device-screen {
  height: 70%;
  width: 93%;
  top: 4%;
  left: 3%;
}
.features-img-1 .img-fluid {
  height: 100%;
  width: 100%;
}
.features-img-2 {
  width: 65% !important;
  left: 49% !important;
  z-index: 3;
  top: 28% !important;
}
@media (max-width: 1199px) {
  .sec-6-bot {
    left: 0;
  }
  .features-img-1 .img-fluid {
    width: 85%;
  }
  .features-img-1 .device-screen {
    width: 80%;
  }
  .features-img-2 {
    top: 26% !important;
    left: 47% !important;
    width: 58% !important;
  }
}
@media (max-width: 991px) {
  .sec-6-bot {
    left: -2%;
  }
  .features-img-1 .img-fluid {
    width: 66%;
  }
  .features-img-1 .device-screen {
    width: 61%;
  }
  .features-img-2 {
    width: 62% !important;
    left: 48% !important;
    top: 27% !important;
  }
}
@media (min-width: 768px) {
  .pricing-sec-wrapper {
    position: relative;
    left: -5%;
  }
}
@media (min-width: 768px) {
  .flickity-prev-next-button.previous {
    top: -230px;
    position: relative;
    left: -100% !important;
  }
  .blog-page-flickity .flickity-prev-next-button.previous {
    top: -196px;
  }
  .blog-page-flickity .flickity-viewport {
    height: 28rem !important;
  }
}
.home-event-input {
  width: 97%;
  height: 97%;
  margin-left: 1%;
  border: none;
  padding-left: 18%;
}
.home-event-input:focus {
  width: 75%;
}
@media (max-width: 767px) {
  .home-event-input:focus {
    width: 75%;
    padding-left: 7%;
  }
}
.home-event-input:focus::placeholder {
  color: transparent;
}
.landing-sec1-div2 {
  display: flex;
}
@media (max-width: 767px) {
  .landing-sec1-div2 {
    justify-content: center;
  }
}
@media (max-width: 351px) {
  .landing-sec1-div2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-error-div-wrapper {
    align-items: center;
  }
}
.home-sec5-img-wrapper {
  position: relative;
  cursor: pointer;
}
@media (min-width: 768px) {
  .process-rows {
    left: 6%;
  }
}
.home-chck-label {
  font-size: 10px;
}
.home-create-btn:hover {
  background: #335eea !important;
}
@media (min-width: 768px) {
  .sec4-img1 {
    position: relative;
    right: 10px;
  }
}
.Typewriter {
  display: inline;
}
.Typewriter__cursor {
  color: #000 !important;
}
@media (min-width: 767px) {
  .sec2-large-card {
    margin: 20px;
  }
}
.pricing-div {
  width: 31%;
}
.pricing-div-center {
  width: 60%;
}
.pricing-card {
  height: 40rem;
}
.pricing-card-main {
  height: 42.75rem;
}
@media (min-width: 992px) {
  .pricing-sub-desc {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .pricing-div {
    width: 100%;
  }
  .pricing-div-main {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .pricing-card {
    height: 41.5rem;
  }
  .pricing-card-main {
    height: 44.25rem;
  }
}
@media (max-width: 282px) {
  .pricing-card {
    height: fit-content;
  }
  .pricing-card-main {
    height: fit-content;
  }
}
.landing-welcome-img {
  position: relative;
  top: -60px;
  width: 150%;
  left: -5%;
}
.landing-sec1-back {
  position: absolute;
  width: 100%;
  top: -5%;
  left: 25%;
  z-index: -1;
}
@media (max-width: 1500px) {
  .landing-welcome-img {
    width: 135%;
  }
}
@media (max-width: 1300px) {
  .landing-welcome-img {
    top: 0;
    width: 120%;
  }
  .landing-sec1-back {
    left: 22%;
  }
  .landing-sec1-back img {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .landing-welcome-img {
    width: 110%;
    left: -5%;
  }
  .landing-sec1-back {
    top: -7%;
    width: 95%;
    left: 18%;
  }
}
.cowork-card {
  height: max-content;
}
@media (max-width: 767px) {
  .cowork-card {
    height: fit-content;
  }
}
.cowork-card img {
  clip-path: ellipse(100% 60% at 50% 40%);
  height: 100%;
  width: 100%;
  /* height: 20vw; */
  /* width: 100%; */
  /* height: 15rem; */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
}
.albums-main-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.albums-main-sub-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 32px;
  color: #6b7c93;
  opacity: 0.8;
}
.albums-main-small {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6c8aec;
}
.albums-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.albums-sub-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 26px;
  color: #6b7c93;
  opacity: 0.8;
}
.albums-sub-heading-span {
  color: #6b7c93;
  font-style: normal;
  font-weight: bold;
  line-height: 152.34%;
  text-decoration-line: underline;
  opacity: 0.8;
  margin: 10px 0;
  padding: 3px;
  white-space: nowrap;
}
.albums-small {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #6c8aec;
}
.cowork-main-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 43px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.cowork-main-sub-heading {
  font-style: normal;
  font-weight: 100;
  line-height: 43px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #335eea !important;
}
.cowork-heading,
.cowork-heading-long {
  font-style: normal;
  font-weight: 100;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #161b2d;
  height: 4rem;
}
.cowork-desc {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #6b7c93;
  height: 6.5rem;
}
.cowork-desc-long {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #6b7c93;
  height: 12.7rem;
}
@media (max-width: 991px) {
  .cowork-desc {
    height: 11rem;
  }
  .cowork-desc-long {
    height: 20rem;
  }
}
@media (max-width: 767px) {
  .cowork-card {
    width: 100% !important;
    margin: auto;
  }
  .cowork-desc {
    height: fit-content;
  }
  .cowork-desc-long {
    height: fit-content;
  }
}
.cowork-checkout {
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #335eea;
}
.cowork-checkout i {
  position: relative;
  left: -10px;
  top: 1px;
}
.cowork-img-div {
  height: 10rem;
}
.features-heading {
  font-style: normal;
  color: #161b2d !important;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 500;
}
.features-heading-2 {
  line-height: 40px;
  letter-spacing: -0.01em;
  font-weight: 100;
  font-style: normal;
}
.features-desc {
  font-weight: 400;
  line-height: 152.34%;
  color: #506690;
}
.features-desc-2 {
  width: 70%;
  margin: auto;
  font-style: normal;
}
@media (max-width: 767px) {
  .features-desc-2 {
    width: 100%;
  }
}
a:hover {
  text-decoration: none;
}
.features-list {
  font-style: normal;
  line-height: 152.34%;
  font-weight: 400;
}
.about-sec-wrapper {
  width: 90%;
  margin: auto;
}

.faq-heading {
  line-height: 24px;
  letter-spacing: -0.01em;
  font-style: normal;
  color: #ffffff;
}
.faq-desc {
  font-style: normal;
  font-weight: 100;
  line-height: 26px;
  color: #869ab8;
}

.pricing-desc {
  font-style: normal;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  color: #161c2d;
}

.features-imgs {
  flex-wrap: nowrap;
}
.review-card {
  height: 32rem;
}
.testimonial-desc {
  font-style: normal;
  font-size: 18px;
  font-weight: 100;
  line-height: 32px;
  text-align: center;
}
@media (max-width: 991px) {
  .testimonial-desc {
    font-size: 17px;
  }
  .review-card {
    height: 37rem;
  }
}
@media (max-width: 767px) {
  .review-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.testimonial-heading {
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.inspiration-img {
  width: 120%;
  height: 100%;
}
@media (max-width: 1300px) {
  .inspiration-img {
    width: 100%;
  }
}

/* account payment */

.StripeElement input {
  display: none !important;
}
/* account security */
.acc-set-head {
  font-weight: 600;
  line-height: 43px;
  letter-spacing: -0.01em;
  color: #ffffff;
}
.acc-set-sub-head {
  font-weight: 100;
  line-height: 29px;
  color: #ffffff;
  opacity: 0.75;
}
.acc-set-sidebar {
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #161c2d;
}
.acc-set-list {
  font-weight: 100;
  line-height: 24px;
  color: #161c2d;
}
.acc-sec-con-heading {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #161c2d;
}

.acc-sec-input-label {
  font-weight: 400;
  line-height: 26px;
  align-items: center;
  color: #161c2d;
}
.acc-sec-input {
  background: #ffffff;
  border: 1px solid #d9e2ef;
  border-radius: 6px;
  height: 50px;
}

.acc-sec-cur-plan {
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.acc-sec-sub-heading {
  font-weight: 100;
  line-height: 23px;
  color: #506690;
}
.acc-sec-plan-list {
  font-weight: 100;
  line-height: 23px;
  color: #161c2d;
}

/* SEARCH EVENT PAGE */
.announcement {
  text-align: center;
  z-index: 1000;
  position: fixed;
  max-width: 450px;
  top: 30%;
  left: 60%;
  width: 30vw;
  min-width: 350px;
  margin-top: -100px;
  margin-left: -250px;
  box-shadow: 0px 24px 64px rgba(22, 27, 45, 0.05);
  border-radius: 5px;
}
@media (max-width: 650px) {
  .announcement {
    left: 60%;
  }
}
@media (max-width: 480px) {
  .announcement {
    left: 68%;
  }
}

.search-event-heading {
  font-weight: 600;
  line-height: 143.22%;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.search-event-sub-heading {
  font-weight: 400;
  line-height: 30px;
  color: #869ab8;
}
/* footer */
.footer-link {
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #bdbdbd;
}
@media (max-width: 767px) {
  .footer-left {
    margin-left: 30%;
  }
}
.search-event-vector {
  position: absolute;
  top: -20vw;
}
@media (max-width: 1100px) {
  .search-event-vector {
    top: -28vw;
  }
}
@media (max-width: 990px) {
  .search-event-vector {
    top: -31vw;
  }
}
@media (max-width: 850px) {
  .search-event-vector {
    top: -37vw;
  }
}

/*  */
/* Event Settings Page */
/*  */
select.input-lg {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

select + i.fa {
  pointer-events: none;
}
.event-settings-dropdown {
  padding-left: 15px !important;
  padding-right: 45px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: #335eea !important;
  color: #fff !important;
  border: none;
  outline: none;
}
.event-settings-dropdown:active {
  outline: none !important;
  border: none !important;
  padding-left: 5px !important;
  padding-right: 40px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.event-setting-media-img {
  position: relative;
  width: 24%;
  margin-right: 1%;
  margin-bottom: 2%;
  background: linear-gradient(
    8.35deg,
    #000000 0.52%,
    rgba(0, 0, 0, 0.1) 31.05%,
    rgba(0, 0, 0, 0) 56.61%
  );
}
.event-setting-media-img img {
  width: 100%;
}
.event-setting-media-img-wrapper {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  .event-setting-media-img {
    width: 30%;
  }
}
@media (max-width: 575px) {
  .event-setting-media-img-wrapper {
    margin-top: 20px;
  }
  .event-setting-media-img {
    width: 32%;
  }
}
@media (max-width: 380px) {
  .event-setting-media-img {
    width: 45%;
  }
}
/*  */
/* Upload Event Page */
/*  */
.upload-img-section {
  height: auto;
}

.upload-event-text-wrapper {
  width: 70%;
}
@media (max-width: 991px) {
  .upload-event-text-wrapper {
    width: 100%;
  }
}
.upload-event-label {
  border: 1px dashed #c6d3e6;
  cursor: pointer;
  width: 100%;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-style: italic;
  font-weight: 100;
  line-height: 26px;
  color: #869ab8;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.file-item {
  background: linear-gradient(
    90deg,
    rgba(52, 199, 89, 0.2) 0%,
    rgba(250, 252, 254, 0) 100%
  );
  border: 1px solid rgba(52, 199, 89, 0.3);
  border-radius: 5px;
  height: 65px;
}
.file-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.file-item .img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.file-item i {
  cursor: pointer;
}

@media (min-width: 768px) {
  .upload-left {
    height: 82vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .upload-left {
    min-height: none;
  }
}

/*  */
/* favorites page */
/*  */
@media (max-width: 767px) {
  .favorites-wrapper .event-media-card {
    width: 32.66%;
    margin-bottom: 1%;
  }
}

.comment_fav_section .infinite-scroll-component__outerdiv {
  height: auto !important;
  overflow: hidden !important;
}
.comment_main_section {
  height: calc(100vh - 70px) !important;
}
.favorite-modal-main-img {
  height: 80%;
  width: 60%;
  top: -5%;
  position: relative;
}
@media (max-width: 1200px) {
  .favorite-modal-main-img {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .favorite-modal-main-img {
    margin-top: 50px !important;
    width: 100%;
    height: 100%;
  }
}
.fav-modal-body {
  height: 90vh;
}
@media (max-width: 767px) {
  .fav-modal-body {
    height: 100vh;
  }
}

.fav-modal-left > center {
  height: 50vh;
}
@media (max-width: 767px) {
  .fav-modal-left > center {
    max-height: 600px;
  }
}
.fav-modal-right {
  height: calc(100vh - 72px);
  overflow: scroll;
}
@media (max-width: 767px) {
  .fav-modal-right {
    height: calc(100vh - 90px);
    overflow: scroll;
  }
}
@media (min-width: 768px) {
  .event-media-section {
    overflow: hidden;
  }
  .fav-modal-right {
    box-shadow: 0px 3.46099px 12.1135px rgba(0, 0, 0, 0.05);
  }
  .fav-modal-left > center {
    height: 100%;
  }
}
.modal {
  height: fit-content;
}
.modal-xl {
  min-width: 90vw !important;
}
@media (max-width: 767px) {
  .modal-xl {
    min-width: 100vw !important;
    height: 100vh;
  }
}
.like_share_section {
  display: flex;
}
@media (max-width: 768px) {
  .like_share_section {
    display: none;
  }
}
@media (max-width: 768px) {
  .favorite-wrapper {
    width: 100vw;
  }
}
.favorite-card {
  width: 22%;
}
.favorites-scroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.favorites-scroll li {
  margin-left: 4%;
  margin-bottom: 5%;
}
@media (max-width: 991px) {
  .favorites-scroll {
    justify-content: center;
  }
}
/*  */
/* Event Page */
/*  */
.example-text-disabled {
  color: #869ab8 !important;
}
.event-nav-join svg {
  fill: #869ab8;
}
.event-nav-join:hover {
  color: #335eea;
}
.event-nav-join:hover svg,
.event-nav-join:hover span {
  color: #335eea !important;
  fill: #335eea;
}
.event-nav-back {
  stroke: #161c2d;
  color: #000000;
}
.event-nav-back:hover {
  stroke: #335eea;
  color: #335eea;
}
.event-share-btn {
  stroke: #869ab8;
}
.event-share-btn:hover {
  stroke: #335eea;
}
.event-settings-btn {
  stroke: #869ab8;
}
.event-settings-btn:hover {
  stroke: #335eea;
}
.event-filter-btn {
  stroke: #869ab8;
}
.event-filter-btn:hover {
  stroke: #335eea;
}

.join-event-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #869ab8;
}

.settings-dropdown-mob {
  margin-bottom: 6px !important;
}
@keyframes videoTogglerAnim {
  0% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    opacity: 0;
  }
}
.filter-options-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
}
.filters-date-options {
  font-size: 15px !important;
}
.download-photos-modal-select {
  width: 45%;
}
@media (max-width: 399px) {
  .download-photos-modal-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) {
  .shareModal {
    width: 460px !important;
  }
}
.nav {
  flex-wrap: nowrap !important;
}
@media (max-width: 320px) {
  .nav {
    flex-wrap: wrap !important;
  }
}
.upload-fixed-section img {
  width: 22px;
  height: 22px;
}
.main-event-content-wrapper {
  margin: auto;
}
.like_share_section {
  min-width: none;
}
.events-main-wrapper {
  width: 90%;
  margin: auto;
  max-width: 1120px;
}
@media (max-width: 575px) {
  .event-main-card {
    margin: auto;
  }
}
.card-zoom {
  box-shadow: 0px 24px 64px rgba(22, 27, 45, 0.05) !important;
  border-radius: 5px !important;
  height: 100%;
}
.delete-event-div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 1;
  background: linear-gradient(to top, transparent 0, rgba(0, 0, 0, 0.5) 100%);
}
.delete-event-div svg {
  margin: 10px;
  float: right;
}
.events-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;
  opacity: 1;
  z-index: 1;
}
.events-overlay-bg {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(204, 204, 204, 0.5046393557422969) 60%,
    rgba(182, 182, 182, 1) 80%
  ); */
  /* background: linear-gradient(
    to bottom,
    transparent 0,
    rgba(0, 0, 0, 0.5) 100%
  ); */
  /* box-shadow: inset 0px 20px 25px 0px rgb(255, 255, 255),
    inset 0 99px 5px 0px rgb(70, 70, 70); */
  /* background: rgb(139, 139, 139);
  background: radial-gradient(
    circle,
    rgba(139, 139, 139, 1) 0%,
    rgba(199, 199, 199, 1) 44%,
    rgba(255, 255, 255, 1) 100%
  ); */
}
.event-img-details {
  z-index: 2;
  position: relative;
  top: 85%;
  left: -5%;
  display: flex;
  flex-wrap: nowrap;
  float: right;
  border-radius: 5px;
}
@media (max-width: 1300px) {
  .event-img-details {
    top: 80%;
  }
}
@media (max-width: 991px) {
  .event-img-details {
    top: 9rem;
  }
}
@media (max-width: 575px) {
  .event-img-details {
    top: 52vw;
  }
}
@media (max-width: 400px) {
  .event-img-details {
    top: 48vw;
  }
}

@media (max-width: 575px) {
  .card-zoom {
    height: 60vw;
  }
}
@media (min-width: 992px) {
  .event-card {
    height: 33vw;
    max-height: 500px;
    min-height: 180px;
  }
}
.event-main-img {
  box-shadow: 0px 24px 64px rgba(22, 27, 45, 0.05) !important;
  border-radius: 5px !important;
}
.hide-event-navbar {
  position: relative;
  animation: navbar-hide 0.3s forwards;
}
.show-event-navbar {
  position: relative;
  animation: navbar-show 0.3s forwards;
}
@keyframes navbar-hide {
  from {
    top: 0;
  }
  to {
    top: -120px;
  }
}
@keyframes navbar-show {
  from {
    top: -120px;
  }
  to {
    top: 0;
  }
}
.selected_height {
  height: 98vh !important;
}
.event_main_content {
  height: auto !important;
}
@media (max-width: 768px) {
  .selected_height {
    height: 95vh !important;
  }
}
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .upload-img-section {
    margin: auto;
  }
}
.event-infinite-scroll {
  display: flex;
  flex-wrap: wrap;
}

.my-masonry-grid_column > div {
  margin: 0 !important;
}
.my-masonry-grid_column {
  padding: 0 !important;
}
.event-media-card {
  margin: auto;
  width: 97%;
  margin-bottom: 3%;
}
.event-media-card img {
  /* filter: drop-shadow(0px 24px 64px rgba(22, 27, 45, 0.05)); */
}
.hide-media-card img {
  filter: blur(5px);
}

.media-img {
  border-radius: 0.375rem;
}
.main-event-content-wrapper .infinite-scroll li {
  padding: 0 !important;
}
@media (min-width: 768px) {
  .main-event-content-wrapper .infinite-scroll {
    margin-top: 73px;
  }
}
@media (max-width: 767px) {
  .left-first-media-card {
    margin-left: 0 !important;
  }
  .right-last-media-card {
    float: right;
    clear: both;
    /* margin-left: 1% !important; */
  }
  .event-media-card {
    list-style: none;
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-bottom: 3%;
  }
  .event-media-card-stacked {
    list-style: none;
    width: 93.6%;
    /* height: 100%; */
    max-width: 516px;
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
  }
  .sm-media-event-back {
    height: 30vw !important;
    width: 100% !important;
  }
}
@media (max-width: 350px) {
  .sm-media-event-back {
    height: 32vw !important;
  }
  .event-media-card {
    /* width: 32.65%; */
  }
}
.event-filter-check {
  /* width: 40%; */
  white-space: nowrap !important;
  margin-right: 10px;
  margin-bottom: 5px;
  color: #bdbdbd;
}
.event-filter-sm {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

/*  */
/*  */
/* Login */
.button_actions:hover {
  background: none;
  color: black;
  box-shadow: 6px 2px 19px 0px rgba(150, 124, 124, 0.75);
  -webkit-box-shadow: 6px 2px 19px 0px rgba(150, 124, 124, 0.75);
  -moz-box-shadow: 6px 2px 19px 0px rgba(150, 124, 124, 0.75);
}
@media (max-width: 991px) {
  .login-main-right {
    padding-left: 0 !important;
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .login-main-left {
    width: 46.6vw;
    max-width: 42rem;
    height: 100%;
    position: relative;
    left: -13vw;
  }
  .login-main-right {
    padding-left: 6vw;
    width: 40vw;
  }
}
@media (max-width: 1355px) {
  .login-main-left {
    left: -10vw;
  }
  .login-main-right {
    padding-left: 10vw;
    width: 44vw;
  }
}
@media (max-width: 1255px) {
  .login-main-left {
    left: -7vw;
    width: 40rem;
  }
  .login-main-right {
    padding-left: 10vw;
  }
}
@media (max-width: 1075px) {
  .login-main-left {
    left: -4vw;
    width: 37rem;
  }
  .login-main-right {
    padding-left: 10vw;
    width: 48vw;
  }
}
@media (max-width: 1010px) {
  .login-main-left {
    left: -2vw;
    width: 34rem;
  }
  .login-main-right {
    padding-left: 7vw;
    width: 48vw;
  }
}

/*  */
/*  */
/* download album */
@media (min-width: 768px) {
  .download-photo-page-text {
    position: relative;
    top: -100px;
    text-align: center;
  }
  .download-photo-page-image img {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .download-photo-page-text {
    position: relative;
    top: 0;
    text-align: left;
  }
  .download-photo-page-image img {
    width: 30vw;
  }
}
@media (max-width: 767px) {
  .download-photo-page-text {
    text-align: center;
  }
}
.downloadLoadingImg {
  animation: downloadLoadingImg 1s infinite forwards;
}
@keyframes downloadLoadingImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */
/*  */
/* Pricing page */
.slider-img-div img {
  height: 100%;
  width: 70%;
}
@media (max-width: 767px) {
  .slider-img-div img {
    width: 50vw;
    max-width: 291px;
  }
  .slider {
    width: 50vw !important;
    max-width: 291px !important;
  }
}
@media (max-width: 575px) {
  .slider-img-div {
    left: 0;
    margin: auto;
    text-align: center;
  }
}
.slider {
  top: 190px;
  -webkit-appearance: none;
  appearance: none;
  width: 70%; /* Full-width */
  margin: 0 auto;
  height: 4px; /* Specified height */
  background: none;
  outline: none; /* Remove outline */

  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}
.slider::before {
  background-color: #7b96f0;
  top: 0px;
  height: 100%;
}
.slider-basic::before {
  width: 0%;
}
.slider-basic-plus::before {
  width: 50%;
}
.slider-pro::before {
  width: 99%;
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 11.25px;
  height: 20px;
  background: #7b96f0;
  border-radius: 3px;
  cursor: pointer; /* Cursor on hover */
}
/*  */
/*  */
/* upload modal */
@media (min-width: 576px) {
  #upload-modal {
    width: 443px;
  }
}
.upload-progress .progress-bar {
  /* background: rgb(52, 199, 89) !important; */
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(51, 94, 234, 1) 100%
  ) !important;
}

.upload-modal-input {
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9e2ef;
  border-radius: 6px;
}
.upload-modal-input::placeholder {
  color: #869ab8;
}

/*  */
/*  */
/* Sigin modal */
.sigin-modal-main-heading {
  font-weight: 600;
  font-size: 31px;
  line-height: 132.02%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.sigin-modal-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #161c2d;
}

/*  */
/*  */
/* media page */
.comment-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #161c2d;
}
.comment-text {
  word-break: break-all;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #031831;
  opacity: 0.7;
}
.comment-footer {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #161c2d;
  opacity: 0.3;
}
.comment-reply-input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 151%;
  color: #869ab8;
}

/*  */
/*  */
/* evnet password modal */
.event-password-modal-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #6e84a3;
  opacity: 0.8;
}
.event-password-modal-body input {
  border: 1px solid #d9e2ef;
  border-radius: 6px;
}
.event-password-modal-body input::placeholder {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #869ab8;
}
@media (min-width: 576px) {
  .event-password-modal {
    width: 452px !important;
  }
}

/*  */
/*  */
/* inactive event */
.inactive-event-right-wrapper {
  width: 90%;
  max-width: 592px;
}
@media (max-width: 991px) {
  .inactive-event-right-wrapper {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .inactive-event-right-wrapper {
    margin: auto;
  }
}

.media-navigation-btn:hover {
  background-color: #335eea;
}

/*  */
/*  */
/*  */
.invoice-table th,
.invoice-table tr {
  white-space: nowrap;
}
.invoice-amount-heading {
  padding-right: 0 !important;
}

/* share event page */

.share-main-heading {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
  color: #384c74;
}
.share-page-bottom {
  color: #384c74;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.share-page-bottom-styled {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  color: #161c2d;
}
.share-page-wrapper {
  width: 52%;
  border: 1px dashed #000000;
}
@media (max-width: 991px) {
  .share-page-wrapper {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .share-page-wrapper {
    width: 100%;
  }
}
.share-page-main-wrapper {
  height: 100vh;
}
@media print {
  .share-page-main-wrapper {
    height: 90vh !important;
  }
}
